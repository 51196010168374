import React, { useState } from 'react';
import { useMutation } from '@apollo/client';
import avo from 'analytics/avo';
import { getTalkativeCustomerId } from 'commons/localStorage';
import { NEWSLETTER_SIGNUP, TRADE_NEWSLETTER_SIGNUP } from 'mocks/mutations';

import useUserCurrentPageType from 'hooks/useUserCurrentPageType';
import useUserData from 'data-hooks/useUserData';
import BriteverifyClient from 'commons/britverify-client';

import CtaButton from 'components/essentials/Cta/CtaButton';
import LabeledTextInput from 'components/consumer/LabeledTextInput';
import { sendFBEvent, FB_EVENTS } from '../../../commons/fbEvents';
import ConsumerNewsletterThankYou from './components/ConsumerNesletterThankYou';

const tradeUserContent = {
  heading: 'Subscribe to the Trade newsletter',
  subTitle:
    'Stay in the know about new releases, future fabric launches, and exclusive perks only for trade members.',
};
const nonTradeUserContent = {
  heading: 'Join the club',
  subTitle:
    'Sign up to enjoy discounts, exclusive sales, and VIP / member-only perks!',
};

const ConsumerNewsletter = () => {
  const inputWrapperRef = React.createRef();
  const [email, updateEmail] = useState('');
  const [errorMessage, updateErrorMessage] = useState('');
  const [isSubmitted, updateIsSubmitted] = useState(false);
  const [mutateNewsletterSignup] = useMutation(NEWSLETTER_SIGNUP);
  const [mutateTradeNewsletterSignup] = useMutation(TRADE_NEWSLETTER_SIGNUP);
  const { isTradeUser } = useUserData();
  const currentPage = useUserCurrentPageType();

  const formLabels = isTradeUser ? tradeUserContent : nonTradeUserContent;

  const validateEmail = async () => {
    const emailRegEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!email) {
      updateErrorMessage('Email address is required');
      return false;
    }
    if (!emailRegEx.test(email)) {
      updateErrorMessage('Email address is invalid');
      return false;
    }
    updateErrorMessage('Validating ...');
    const validate = await BriteverifyClient.emailLookup(email);
    updateErrorMessage('');
    if (!validate?.valid && validate?.message) {
      updateErrorMessage(validate.message);
      return false;
    }

    return true;
  };

  const onSubmit = async () => {
    const validEmail = await validateEmail(email);
    if (!validEmail) {
      if (inputWrapperRef.current) {
        inputWrapperRef.current.querySelector('input')?.focus();
      }
      return;
    }
    const variables = { email, source: 'global footer' };
    updateIsSubmitted(true);
    if (isTradeUser) {
      mutateTradeNewsletterSignup({ variables });
    } else {
      mutateNewsletterSignup({ variables });
    }

    sendFBEvent(FB_EVENTS.NEWSLETTER_SIGNUP, {
      content_name: 'NewsletterSignup',
    });

    avo.emailAcquired({
      userId_: '',
      email,
      location: currentPage,
      optedInMarketing: true,
      backendEmail: email,
      component: isTradeUser
        ? avo.Component.TRADE_NEWSLETTER_SIGNUP
        : avo.Component.NEWSLETTER_SIGNUP,
      talkativeCustomerId: getTalkativeCustomerId(),
    });
  };
  return (
    <div className="[border-top:1px_solid_#f1f1f1] [border-bottom:1px_solid_#f1f1f1] print:hidden">
      <div className="px-[3.333vw] py-8 flex justify-between mx-auto my-0 items-center max-w-[1400px] min-[1500px]:px-0 min-[1500px]:py-14 [&_button]:rounded-tl-none [&_button]:rounded-bl-none [&_button:focus]:shadow-none [&_button:hover]:shadow-none max-lg:flex-wrap max-lg:justify-center max-lg:text-center">
        <div className="lg:flex-[2_1] max-lg:w-full">
          <div className="mx-auto my-0">
            <h2 className="text-xl font-bold m-0">{formLabels.heading}</h2>

            <div className="text-base text-gray">{formLabels.subTitle}</div>
          </div>
        </div>

        <div className="mt-8 md:mt-6 lg:mt-0 lg:flex-[1_1] max-lg:w-full">
          <div className="flex w-full mx-auto my-0 justify-around">
            {isSubmitted ? (
              <ConsumerNewsletterThankYou />
            ) : (
              <>
                <div className="flex-[1_1]" ref={inputWrapperRef}>
                  <LabeledTextInput
                    label="E-mail"
                    id="email"
                    type="email"
                    name="email"
                    className="customized-input"
                    value={email}
                    onChange={e => {
                      updateEmail(e.target.value);
                    }}
                    errorMessage={errorMessage}
                    action={
                      <CtaButton
                        block
                        styling="solid-button"
                        color="brand"
                        onClick={onSubmit}
                      >
                        Sign Up
                      </CtaButton>
                    }
                    actionHasPadding={false}
                    actionStretchHeight
                  />
                </div>
                <div className="consumer-signup-button-container" />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ConsumerNewsletter;
